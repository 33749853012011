<script setup lang="ts">
// Components
import IncidentCommentCard from '~/app-modules/incident/components/cards/incidentCommentCard.vue';
import IncidentInformationCard from '~/app-modules/incident/components/cards/incidentInformationCard.vue';
import incidentManageModal from '../components/modals/incidentManageModal.vue';
import incidentResolveModal from '../components/modals/incidentResolveModal.vue';

// Composable
const route = useRoute();

// Store
const incidentService = incidentStore();

// Data
const manageModal = ref<boolean>(false);
const resolveModal = ref<boolean>(false);
const incidentId = ref<number>(parseInt(route.params.id as string));
const incident = computed(() => incidentStore().getIncidentById(incidentId.value));

// Load
const load = async () => {
  await incidentStore().loadIncidents();
};

load();
</script>
<template>
  <div v-if="incidentService.loading" class="flex items-center justify-center w-full h-full bg-gray-50">
    <Loader class="w-[50px]" />
  </div>
  <div v-else-if="!incident" class="flex items-center justify-center w-full h-full bg-gray-50">
    <p>{{ $t('global.no_data') }}</p>
  </div>
  <div v-else class="flex flex-col w-full h-full bg-gray-50">
    <ui-header :title="`${$t('global.incident')} - ${incident.name}`" back-url="/incidents">
      <template v-if="incident.status !== 'solved' && incident.status !== 'closed'" #actions>
        <ui-button color="secondary" left-icon="Pen" @click="manageModal = true">
          {{ $t('global.edit') }}
        </ui-button>
        <ui-button left-icon="Check" @click="resolveModal = true">
          {{ $t('global.close_issue') }}
        </ui-button>
      </template>
    </ui-header>

    <div class="overflow-y-auto">
      <div class="grid grid-cols-2 gap-4 p-4 pb-0">
        <ui-stat-card
          v-if="incident.area.name"
          :loading="incidentService.loading"
          class="col-span-1"
          :title="$t('incident.area')"
          :data="incident.area.name"
          icon="Building2"
          type="info"
        />
        <ui-stat-card
          v-if="incident.cost"
          :loading="incidentService.loading"
          class="col-span-1"
          :title="$t('incidents.impact')"
          :data="incident.cost"
          type="euro"
        />
      </div>

      <div class="grid grid-cols-1 gap-4 p-4 pt-4">
        <incident-information-card :incident="incident" />
        <incident-comment-card :incident="incident" />
      </div>
    </div>
  </div>

  <incident-resolve-modal v-if="incident" v-model="resolveModal" :incident="incident" />
  <incident-manage-modal v-if="incident" v-model="manageModal" :incident="incident" />
</template>
